import { observer } from 'mobx-react'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import WordpressPersonoService from '../../../services/WordpressPersonoService'
import { Timer } from '../../../utils/customTypes'
import NavbarDesktop from '../../molecules/NavbarDesktop'
import NavbarMobile from '../../molecules/NavbarDesktop/NavbarMobile'
import SearchStore from '../../stores/SearchStore/SearchStore'
import { ILanguageVersionInterface } from '../../templates/LanguageInterface'

const navItems = [
  { path: '/insights/category/testes-e-quizes', key: 'test-and-quiz' },
  { path: '/insights/category/convidados', key: 'invited-authors' },
]

const enNavItems = [
  { path: '/about-us', key: 'about-us' },
  { path: '/', key: 'insights' },
  { path: '/pillow', key: 'pillow' },
]

const content = [
  { path: '/insights/category/sono-e-ciencia', key: 'category-1' },
  { path: '/insights/category/sono-e-performance', key: 'category-2' },
  { path: '/insights/category/sono-e-saude', key: 'category-3' },
  { path: '/insights/category/sono-e-cultura', key: 'category-4' },
  { path: '/insights/category/sono-e-idade', key: 'category-5' },
  { path: '/insights/category/disturbios-do-sono', key: 'category-6' },
  { path: '/insights/category/sono-e-tecnologia', key: 'category-7' },
  { path: '/insights/category/saude-e-bem-estar', key: 'category-8' },
]

export interface NavbarProps {
  searchStore: SearchStore
  menuItems: PathKeyItems[],
  isEnVersion: boolean,
  dropdownItems: PathKeyItems[]
}

export interface PathKeyItems {
  key: string,
  path: string
}

const searchStore = new SearchStore()

const Header = observer((props: ILanguageVersionInterface) => {
  const [showMobileNav, setShowMobileNav] = useState(true)
  const wpService = new WordpressPersonoService(props.language ?? 'pt')
  const timer = useRef<Timer>()

  useEffect(() => {
    checkResize()
    window.addEventListener('resize', checkResize)
    searchStore.init(wpService, props.language, timer)
  }, [])

  const checkResize = () => {
    setShowMobileNav(window.innerWidth < 1065)
  }

  return (
    <>
      {showMobileNav
        ? <NavbarMobile
          searchStore={searchStore}
          isEnVersion={props.language == 'en'}
          menuItems={props.language == 'en' ? enNavItems : navItems}
          dropdownItems={content}
        />
        :
        <NavbarDesktop
          searchStore={searchStore}
          isEnVersion={props.language == 'en'}
          menuItems={props.language == 'en' ? enNavItems : navItems}
          dropdownItems={content}
        />
      }
    </>
  )
})

export default Header